*{
    padding: 0%;
    margin: 0%;
}


::-webkit-scrollbar{
    width: 8px;
}

::-webkit-scrollbar-track{
    background-color: white;
}

::-webkit-scrollbar-thumb{
    background-color: crimson;
    border-radius: 20px;

}



.app{
    height: max-content;
    width: 100%;
}